@import url('https://fonts.googleapis.com/css2?family=Abel&family=Dosis:wght@200;700&family=Kanit:ital,wght@0,100;0,700;1,300&family=Lobster&family=Orbitron:wght@400;700&family=Play:wght@400;700&family=Raleway:wght@100;300;700&family=Righteous&family=Roboto+Condensed:ital,wght@0,300;0,700;1,300&family=Teko:wght@300;700&family=Titillium+Web:wght@200;300;700&display=swap');
*{
  /* font-family: 'Advent Pro', sans-serif; */
  /* font-family: 'Oswald', sans-serif; */
  /* font-family: 'Rajdhani', sans-serif; */
  /* font-family: 'Montserrat', sans-serif; */
  /* font-family: 'Abel', sans-serif; */
/* font-family: 'Dosis', sans-serif; */
/* font-family: 'Kanit', sans-serif; */
/* font-family: 'Lobster', cursive; */
/* font-family: 'Orbitron', sans-serif; */
/* font-family: 'Play', sans-serif; */
/* font-family: 'Raleway', sans-serif; */
/* font-family: 'Roboto Condensed', sans-serif; */
/* font-family: 'Teko', sans-serif; */
font-family: 'Titillium Web', sans-serif;
  margin:0; padding:0;
  box-sizing: border-box;
  outline: none; border:none;
  transition: all .2s linear;
}
body{
  animation: fadeInLeft 1s linear;
}
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-gray-900: #212529;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
}
.footer--header{
  width: 100%;
  /* height: 50px; */
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2rem;
  border-top: 1px solid gainsboro;
}
.footer--header > p{
  margin-bottom: 0 !important;
  margin-left: 2rem;
  font-size: 1.2rem;
  color:rgb(12, 52, 103);
  /* font-weight: bold; */
}
header{
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid gainsboro;
}
.navbar{
  width: 95%;
  height: 95%;
  display: flex;
  border-radius: 1rem;
}
.navbar--left{
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid coral; */
}
.navbar--logo{
  width: 80px;
  height: 80px;
}
.navbar--logo img{
  width: 100%;
  height: 100%;
}
.navbar--center{
  width: 80%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
    /* border: 1px solid coral; */
}
.navbar--center--content{
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid coral; */
  width: 100%;
}
.navbar--right{
  width: 10%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid coral; */
  /* border: 1px solid coral; */
}
.navbar--right--content{
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.content--header{
  width: 100%;
  text-align: center;
  font-size: 2rem;
}
.liste{
  padding: 1rem;
  text-decoration: none;
  color:rgb(12, 52, 103);
  /* font-weight: bold; */
  font-size: 1.1rem;
}
.liste:hover{
  color: coral;
}
.rows{
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  animation: fadeInUp 1.5s linear;
}
.container__header{
  text-align: center;
  color: coral;
  margin: 1rem 0;
  padding: 1rem;
  border-bottom: 1px  solid gainsboro;
  border-top: 1px solid gainsboro;
}
.container__description{
  text-align: center;
}
.container__details{
  width: 100%;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.card{
  width: 300px;
  margin: 0.5rem;
  text-align: center;
}
.titre{
  font-size: 25px;
  color: rgb(15, 52, 90);
}
.images{
  width: 100%;
  height: 120px;
  justify-content: center;
  display: flex;
}
.images img{
  width: 100px;
  height: 100px;
}

.footer{
  width: 100%;
  padding: 10px 200px;
  /* background-color:rgb(12, 52, 103); */
  color: rgb(12, 52, 103);
  font-weight: bold;
}
.right{
  width: 100%;
  text-align: center;
  border-bottom: 3px solid coral;
}

.box-containers{
  width: 100%;
  height:400px;
  border-radius: 10px;
  background-color:white;
  margin-bottom: 10px;
  background: url('https://vss.astrocenter.fr/habitatpresto/pictures/29524408-adobestock-208767262.jpg');
  object-fit: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  animation:fadeInRight 1s linear;
}
  .navbar--login{
    font-size: 1rem;
  }

  .container-contents{
    width: 100%;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;
  }
 .content{
    width: 77%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    /* position: relative; */
  }
.sidebar{
    width: 20%;
    padding: 1.5rem;
    position: sticky;
    top: 0;
    transition: all .4s ease-in-out;
    height: 100vh;
    /* box-shadow: 0 0 9px black; */
  }
  .cartes{
    width: 250px;
    height: 450px;
    display: inline-block;
    margin-left: 30px;
    border-radius: 20px;
  }
  .images{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 150px;
  }
  .images img{
    width: 100%;
    height: 150px;
    border-radius: 10px;
  }
.link{
  text-decoration: none;
  width: 250px;
}
.container--home{
  width: 100%;
  padding:0 100px;
  height: 90vh;
  /* border: 1px solid coral; */
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 2rem;
}
.container--left--part{
  width: 60%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid gainsboro; */
}
.container--left--part--container{
  width: 90%;
  height: 100%;
  border: 1px solid gainsboro;
}
.container--right--part{
  width: 40%;
  height: 100%;
  padding: 1rem;
}
.container--left--part--content{
  width: 100%;
  height: 100%;
  /* border: 1px solid coral; */
}
.container--left--part--container--image{
  width: 100%;
  height: 77%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.container--left--part--container--image--box{
    width: 90%;
    height: 90%;
    /* border: 1px solid gainsboro; */
}
.container--left--part--container--image--box img{
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
}
.thumb{
  width: 100%;
  height: 22%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* border: 1px solid coral; */
}
.thumb__images{
  width: 25%;
  height: 90%;
  margin-left: 0.3rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  /* border: 1px solid coral; */
}
.thumb__images img{
  width: 100%;
  height: 100%;
}

.name--article{
  color: rgb(15, 52, 90);
  /* color: #000; */
}
.btn--close--part{
  width: 100%;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  font-size: 1.5rem;
}
.btn--close--part > p{
  margin-bottom: 0 !important;
}
.part__new__product{
  width: 100%;
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 1rem;
}
.part__newsletter{
  width: 100%;
  /* height: 100px; */
  padding: 1rem 9rem;
  background-color: coral;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
}
.part__newsletter__first{
  width: 50%;
}
.part__newsletter__second{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 0.7rem;
  /* border: 1px solid coral; */
}
.btn__newsletter{
  padding: 0.7rem;
  background-color: #fff;
  border-radius: 0.7rem;
  width: 100px;
}
.banner-2{
  margin-bottom: 1rem;
}
.link__btn{
  width: 70%;
  text-align: center;
  border-radius: 0.3rem;
  font-size: 1rem;
  background-color:rgb(15, 52, 90);
}
.link__btn:hover{
  background-color:coral;
}
.header__article{
  font-size: 1.2rem;
  font-weight: bold;
}
.part__smartphone{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
}
.items{
  width: 280px;
  /* height: 400px; */
  text-align: center;
  margin-bottom: 1rem;
  border: 2px solid darkgray;
  border-radius: 0.7rem;
  background-color:rgb(236, 234, 234);
}
.part__products__header{
  width: 100%;
  text-align: center;
}
.header__accessories{
  font-weight: bold
}



.left--part--container{
  position: fixed;
  left : -1000px;
  transition: ease-in-out 2s;
}
.btn--close--left--part{
  position: relative;
  top:0;
  left: 88%;
  background-color: #1320DC;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50%;
  color: white;
}
.block--login{
  display: none;
}
.main--orders--container--right--block{
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: auto;
}
.container--login--header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}
.btn--header{
  border-radius: 0.7rem;
  color: #f3f5f9;
  width: 130px;
  padding: 0.6rem;
  transition: ease-in-out .2s;
}
.btn__login{
  padding: 0.7rem;
  background-color: #f0c14b;
  color: #fff;
  font-weight: bold;
  border-radius: 0.7rem;
  margin-top: 0.7rem;
}
.btn__login:hover {
  background-color: #fff;
  border: 1px solid coral;
  color: coral;
}

.user__part > p{
  margin-bottom: 0 !important;
}
.btn__log{
  width: 100%;
  /* background-color: #f0c14b; */
  color: rgb(15, 52, 90);
  border-radius: 0.7rem;
  padding: 0.5rem;
  font-weight: bold;
}
.na{
  text-decoration: none;
  color: rgb(15, 52, 90);
}
.btn--connexion{
  /* background-color:#f0c14b; */
  padding: 0.4rem;
  border-radius: 0.7rem;
  color: rgb(15, 52, 90);
  font-size: 0.9rem;
}
.cart--content{
  display:flex ;
  align-items: center;
  justify-content: center;
}
.cart--articles--container{
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cart--articles--container--content{
  width: 50%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.caroussel__detail{
  font-size: 4rem;
  margin-bottom: 3rem;
}

.left__content{
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid gainsboro;
}
.left__content__header{
  margin-left: 1rem;
  font-weight: bold;
}
.user--part--account{
  width: 100%;
  text-align: center;
}
.user--name--container{
  width: 100%;
  height: 100px;
  border-radius: 0.7rem;
  border: 1px solid gainsboro;
}
.btn--log{
  padding: 0.5rem;
  border-radius: 0.5rem;
  width: 100%;
   background-color:#f0c14b;
}
.marquee{
  background-color:#f0c14b ;
}
.icon--menu{
  display: none;
}
.menu {
  position: fixed;
  width: 300px;
  height: 100vh;
  top: 0;
  left: -1000px;
  display:flex;
  align-items: center;
  justify-content: center;
  z-index: 10000;
  transition: ease-in-out 1s;
  box-shadow: 0 0 9px 5px black;
  background-color:#fff;
  padding: 1rem;
}
.show--menu{
  left: 0;
  transition: ease-in-out 1s;
}
.sidebar--menu{
  width: 100%;
  height: 95%;
  text-align: center;
  /* font-weight: bold; */
}
.sidebar--menu > p{
 padding: 1rem;
}
.btn--close--sidebar{
  width: 100%;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 2rem;
  cursor: pointer;
}
.btn--close--sidebar > i{
    font-size: 1.2rem;
    color: #0069ff;
}
.menu__content{
  padding: 1rem;
  width: 100%;
  height: 500px;
}
.menu__content__liste{
  width: 100%;
  margin-bottom: 1rem;
}
.logo--resp{
  display: none;
}
.more__order{
  width:100%;
  display: none;
}
.more__order__res{
  width:100%;
  display: flex;
}
.container__cards{
width: 100%;
display: flex;
align-items: center;
justify-content: space-around;
flex-wrap: wrap;
}
 .cards{
  width: 280px;
  height: 300px;
  border-radius: 0.7rem;
  margin: 0.7rem;
  border-bottom: 1px solid gainsboro;
  /* box-shadow: 0 0 10px gainsboro; */
 }
 .cards__images{
  width: 100%;
  height: 55%;
  /* border: 1px solid coral; */
 }
 .cards__images img{
  width: 100%;
  height: 100%;
  border-radius: 0.7rem;
 }
 .cards__body{
  width: 100%;
  height: 45%;
  padding-top: 0.5rem;
  text-align: center;
 }
 .cards__body__description{
  font-weight: bold;
  font-style: italic;
 }
 .cards__body__price{
  font-weight: bold;
  font-style: italic;
 }
 .btn__details{
  padding: 0.5rem;
  background-color: coral;
  border-radius: 0.7rem;
 }
 .btn__details:hover{
  background-color: white;
  border: 1px solid coral;
 }
 .container__images{
  width: 100%;
  padding: 1rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-around;
  flex-wrap: wrap;
  /* border: 1px solid coral; */
 }
 .images_box{
    width: 300px;
    height: 200px;
    margin: 0.5rem;
    border-radius: 0.7rem;
 }
 .images_box img{
  width: 100%;
  height: 100%;
  border-radius: 0.7rem;
}
.container__content{
  text-align: center;
  width: 100%;
  margin-top: 1rem;
}
.container__content > p{
 margin-bottom: 0;
}
.container__description{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  /* border: 1px solid coral; */
}
.container__description__block{
  width:65%;
  padding: 1rem;
  /* border: 1px solid coral; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.description__box{
  width:85%;
  height: 550px;
  /* border: 1px solid gainsboro; */
  margin: 0.5rem;
  border-radius: 0.7rem;
}
.description__images{
  width: 100%;
  height: 70%;
  border-radius: 0.7rem;
  /* border: 1px solid coral; */
}
.description__images img {
  width: 100%;
  height: 100%;
  border-radius: 0.7rem;
}
.description > p{
  margin-bottom: 0 !important;
}
.description__title{
  color: coral;
  font-weight: bold;
  font-size: 1.3rem;
  margin-bottom: 0 !important;
  text-transform: uppercase;
  margin-top: 1rem;
}
.horaires{
  color: coral;
  font-weight: bold;
}
.btn__contact{
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: coral;
  color: white;
}
.btn__contact:hover{
  padding: 0.7rem;
  background-color: white;
  color: coral;
  border: 1px solid cadetblue;
}
.container__form{
  width: 100%;
  padding: 0.7rem;
  /* border: 1px solid cadetblue; */
}
.btn__form{
  padding: 0.5rem;
  border-radius: 0.5rem;
  background-color: coral;
  width: 100px;
}
.main{
  text-align: center;
  margin-top:30px ;
}
.flex_row{
  justify-content: center;
  display: flex;
  padding-top: 20px;
}

.thumbnail{
padding-left: 20px;
}
.clicked{
border: 5px solid black;
}
.cover__wrapper{
  width: 100%;
  height: 400px;
  /* border: 1px solid coral; */
  background-image: url('https://www.assoyam.com/images/cover/cover-page-hotel.jpg');
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cover__header{
  color: white;
  font-size: 3rem;
  /* text-decoration-line: underline;
  text-decoration-style: wavy;
  text-decoration-color: coral; */
  /* border-bottom: 2px solid coral; */
}
.main--contact{
  margin-top: 1rem;
}
.home__name{
  font-size: 1.7rem;
  font-weight: bold;
  color: rgb(15, 52, 90);
}
@media only screen and (max-width:576px) {
  header{
    height: 70px;
  }
  .navbar--center--content{
    display: none;
  }
  .navbar--left{
    width: 10%;
    display: flex;
    justify-content: flex-start;
  }
  .navbar--center{
    width: 70%;
  }
  .navbar--right{
    width: 10%;
    display: flex;
    justify-content: flex-end;
  }
  .icon--menu{
    width: 50%;
    height: 100%;
    font-size: 1.3rem;
    align-items: center;
    display: flex;
    justify-content: center;
    color: rgb(15, 52, 90);
    cursor: pointer;
  }
  .logo--resp{
    display: flex;
  }
  .navbar--logo{
    display: none;
  }
  .navbar--logo--resp{
    width: 65px;
    height: 65px;
  }
  .navbar--logo--resp img{
    width: 100%;
    height: 100%;
  }
  
  .part__new__product{
    justify-content: center;
  }
  .container__description__block{
    width:100%;
    padding: 1rem;
    margin-bottom: 2rem;
  }
  .description__box{
    width:100%;
    /* height: 400px; */
    border-radius: 0.7rem;
  }
  .description__images{
    width: 100%;
    height: 45%;
    border-radius: 0.7rem;
    /* border: 1px solid coral; */
  }
  .container__images{
    width: 100%;
    padding:0 ;
   }
   .images_box{
      width: 100%;
   }
  .part__newsletter{
    width: 100%;
    padding: 1rem 2rem;
    display: block;
    background-color: coral;
    margin-top: 1rem;
  }
  .part__newsletter__first{
    width: 100%;
    color: #000;
    font-weight: bold;
  }
  .part__newsletter__second{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 1rem;
    /* border: 1px solid coral; */
  }
  .btn__newsletter{
    padding: 0.7rem;
    background-color: #fff;
    border-radius: 0.7rem;
    width: 100px;
  }
  .sidebar{
    display: none;
  }
  .part__accessories{
    width: 100%;
    justify-content: center;
  }
  .container-contents {
    width: 100%;
    justify-content: center;
  }
  .society--details{
    width: 100%;
    padding: 0.7rem;
    display: flex;
    flex-wrap: wrap;
  }
  .container--home{
    width: 100%;
    height: 100vh;
    padding:0;
    display: block;
    margin-top: 2rem;
  }
  .container--left--part{
    width: 100%;
    height: 250px;
  }
  .container--right--part{
    width: 100%;
    padding: 1rem;
  }
  .container--left--part--content{
    width: 100%;
    height: 100%;
    /* border: 1px solid coral; */
  }
  .container--left--part--container--image{
    width: 100%;
    height: 77%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container--left--part--container--image--box{
      width: 90%;
      height: 90%;
      /* border: 1px solid gainsboro; */
  }
  .cover__header{
    font-size: 1.5rem;
  }
  .cover__wrapper{
    height: 250px;
  }
  .footer--header{
    width: 100%;
    display: block;
    margin: 2rem 0;
    text-align: center;
  }
  .footer{
    width: 100%;
    padding: 10px 0;
    color: rgb(12, 52, 103);
    font-weight: bold;
    text-align: center;
    border-bottom: 3px solid coral;
  }
 }